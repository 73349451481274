.header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    transition: all 0.5s;
    .logo {
        padding: 0 0 0 3rem;
        height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width:100%;
            max-height: 80px;
            max-width: 175px;
        }
    }
    .menu-toggle {
        display: none;
    }
    nav {
        display: flex;

        & > ul {
            margin: 0;
            display: flex;
            & > li {
                vertical-align: top;

                & > a,
                & > .dropdown-header {
                    position: relative;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    height: 10rem;
                    font-size: 2rem;
                    font-weight: 500;
                    padding-right: 6rem;
                    color: var(--dark);
                    z-index: 300;
                }

                &:hover {
                    & > a,
                    & > .dropdown-header {
                        color: var(--light);
                    }
                    ul.dropdown {
                        transform: translateY(0%);
                    }
                }

                ul.dropdown {
                    background-color: rgba($white, 0.9);
                    transition: all 0.5s;
                    transform: translateY(-100%);
                    padding: 0 0 1.5rem;
                    margin: 0 0 0 -6rem;
                    position: absolute;
                    z-index: 250;
                    border-radius: 0 0 4px 4px;

                    li {
                        a {
                            display: block;
                            padding: 1.5rem 6rem;
                            transition: all 0.5s;
                            &:hover {
                                padding-left: 7rem;
                                padding-right: 5rem;
                            }
                        }
                    }
                }

                &.button {
                    a {
                        border-radius: unset;
                        display: flex;
                        align-items: center;
                        height: 10rem;
                        padding-left: 6rem;
                        color: var(--light-back-link);
                        background-color: var(--light);
                        &:hover {
                            color: var(--dark-back-link);
                            background-color: var(--dark);
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $breakpoint-tablet-min) and (max-width: $container-lg-width) {
        nav {
            & > ul {
                & > li,
                & > li.button {
                    & > a,
                    & > .dropdown-header {
                        padding-right: 2rem;
                        height: 8rem;
                    }
                }
                & > li.button {
                    & > a {
                        height: 8rem;
                        padding-left: 3rem;
                    }
                }
            }
        }
    }

    @media (max-width: $container-lg-width) {
        height: 8rem;
        .logo {
            padding-left: 1rem;
            height: 8rem;
            img {
                max-height: 7rem;
                max-width: 15rem;
            }
        }
    }

    @media (max-width: $breakpoint-tablet-max) {
        &.hide-mobile-nav {
            nav {
                ul {
                    li:not(.button) {
                        display: none;
                    }
                    li.button a {
                        height: 8rem;
                        padding-left: 3rem;
                        padding-right: 2rem;
                    }
                }
            }
        }
        &:not(.hide-mobile-nav) {
            position: relative;
            overflow: hidden;

            width: 100vw;
            box-sizing: border-box;

            .menu-toggle {
                outline: none;
                box-sizing: border-box;
                border: none;
                background-color: transparent;

                width: 8rem;
                height: 8rem;
                padding: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                span {
                    transition: all 0.1s;
                    display: block;
                    background-color: var(--dark);
                    height: 4px;
                    border-radius: 3px;
                    width: 4rem;
                }
            }

            nav {
                order: 3;
                opacity: 0;
                transition: all 1s;
                transform: translatey(100%);
                overflow-y: scroll;
                box-sizing: border-box;
                display: block;
                position: absolute;
                top: 8rem;
                left: 0;
                width: 100vw;
                height: calc(100vh - 8rem);
                height: calc(var(--vh) - 8rem);
                background-color: #fff;
                z-index: 500;
                & > ul {
                    flex-direction: column;
                    & > li {
                        display: block;

                        ul {
                            display: none;
                        }

                        a,
                        & > .dropdown-header {
                            height: auto;
                            padding: 3rem;
                            &.tapped {
                                background: var(--grey);
                                & + ul {
                                    display: block;
                                    background: rgba($grey-back, 0.5);
                                    transform: unset;
                                    position: relative;
                                    padding-top: 1.5rem;
                                    li {
                                        padding-left: 6rem;
                                        padding-right: 3rem;
                                    }
                                }
                            }
                        }

                        & > .dropdown-header {
                            &::after {
                                position: absolute;
                                right: 3rem;
                                @include icon;
                                @include iArrow;
                                font-size: 2.5rem;
                                line-height: 1;
                                transition: all 0.5s;
                            }
                            &.tapped {
                                &::after {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }
            }

            .menu-open & {
                height: 100vh;

                nav {
                    opacity: 1;
                    transform: translatey(0%);
                }
                .menu-toggle {
                    position: relative;
                    box-sizing: border-box;
                    display: block;
                    span {
                        position: absolute;
                        top: calc(50% - 2px);
                        right: 2rem;
                        &:nth-child(2) {
                            opacity: 0;
                        }
                        &:first-child {
                            transform: rotate(45deg);
                        }
                        &:last-child {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }
}

.menu-open {
    position: fixed;
    max-width: 100vw;
}
