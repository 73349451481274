.cta {
    &-content {
        display: flex;
        justify-content: center;
        p {
            margin-bottom: $xs-pixels;
        }
        > div {
            max-width: $container-lg-width;
            width:100%;
        }
    }

    &__withimage {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-areas: "content image";

        .cta-content {
            padding-right:5%;
            grid-area: content;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            > div {
                padding:$xs-pixels;
                padding-right:0;
                max-width: calc(#{$container-lg-width}/2 - 6rem);
            }
        }

        .cta-image {
            margin-left:5%;
            grid-area: image;

            img {
                width: 100%;
            }
        }
    }



    &__swap {
        grid-template-columns: 50% 50%;
        grid-template-areas: "image content";

        .cta-content {
            padding-left:5%;
            justify-content: flex-start;
            >div{
                padding-right:$xs-pixels;
                padding-left:0;
            }
        }

        .cta-image{
            margin-left:0;
            margin-right:5%;
        }
    }

    &__center {
        .cta-content {
         

            > div {
                text-align: center;

                hr {
                    margin-inline-start: auto;
                }
            }
        }
    }

    @media (max-width: $breakpoint-mobile-max) {
        grid-template-columns: 1fr;
        grid-template-areas: "image" "content";

        .cta-content {
            padding-right: 0;
            padding-left: 0;
            > div {
                max-width: unset;
            }
        }

        &__withimage {
            grid-template-columns: 1fr;
            grid-template-areas: "image" "content";

            .cta-content {
                padding-right: 0;
                padding-left: 0;
                > div {

                    max-width: unset;

                    @media (max-width: $breakpoint-tablet-max) and (min-width: $breakpoint-tablet-min){
                        padding-left: $md-pixels;
                        padding-right: $md-pixels;
                    }
                    @media (max-width: $breakpoint-mobile-max){
                        padding-left: $sm-pixels;
                        padding-right: $sm-pixels;
                    }
                    @media (max-width: $breakpoint-xsmobile-max){
                        padding-left: $xs-pixels;
                        padding-right: $xs-pixels;
                    }
                }
            }
            .cta-image {
                margin-right:0;
                margin-left:0;
            }
        }
    }
}

.strip-cta {
    &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: $xs-pixels;
        padding-right: $xs-pixels;
    }
}
