.b2bheader {
    position: relative;
    &-content {
        z-index: 200;
        box-sizing: border-box;
        position: relative;
        max-width: $container-lg-width;
        margin:0 auto;
        height: calc(100vh - 100px);
        max-height: 800px;
        min-height:600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 6rem 2rem;
        text-align: center;
        color: --var(dark-back-text);

        &-title {
            h1 {
                text-transform: uppercase;
                font-weight: 900;
                line-height: 1;
                font-size:5rem;
                @media (min-width: $breakpoint-lgdesktop-min) {
                   font-size: 12rem;
                }
                @media (min-width: $breakpoint-tablet-min) {
                    font-size: 10rem;
                }
            }
            h2 {
                font-weight: 300;
            }
            @media (max-width: $breakpoint-tablet-max) {
                margin-bottom: 3rem;
            }
        }

        @media (max-width: $breakpoint-tablet-max) {
            max-height: unset;
            height:unset;

        }
    }

    &-list {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1.5rem;
        width: 100%;
        margin: 3rem 0 6rem 0;
        @media (max-width: $breakpoint-tablet-max) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &-cta {
        box-sizing: border-box;
        border-radius: 4px;
        border:none;
        // flex: 0 0 calc(20% - 3rem);
        display: block;
        background-color: $white;
        padding: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 16rem;
        img{
            max-width: 150px;
            max-height: 100px;
        }

        &:hover {
            @include boxShadow;
        }

        // @media (max-width: $breakpoint-tablet-max) {
        //     flex: 0 0 calc(33% - 3rem);
        //     margin:1.5rem;
        // }

        @media (max-width: $breakpoint-mobile-max) {
            img{
                max-width: 10rem;
                max-height: 10rem; 
            }
            padding: 1.5rem;
            min-height: 12rem;
        }

        
    }

    &-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;

        &.overlay {
            &::after {
                content: "";
                display: block;
                position: absolute;
                background-color: $black;
                opacity: 0.5;
                width: 100%;
                height: 100%;
                z-index: 150;
                top: 0;
                left: 0;
            }
        }
    }
}
