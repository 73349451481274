.logo-list {

        margin: 0 auto;
        padding: 0 $sm-pixels;
        max-width: 1440px;
        text-align: center;

        p {
            margin-bottom: 3rem;
        }

        img{
            width:100%;
            margin-bottom: 3rem;
        }
}