.rich-text {
  margin-left:auto;
  margin-right:auto;
  max-width: $container-lg-width;
  &__limitedwidth{
    max-width: $container-md-width;
  }

  p {
    line-height: 3rem;
    margin-bottom: 3rem;
  }
  li {
    line-height: 3rem;
  }
  ul,ol {
    padding-left: 3rem;
    margin-bottom: 3rem;
    li{
      margin-bottom: .5rem;
    }
    ul,ol{
      margin-bottom:unset;
    }
  }

  ul{
    li {
      &::before {
        content: "•";
        font-size: 3rem;
        display: inline-block;
        color: var(--light);
        transform: translateY(3px);
        margin-left: -2.5rem;
        width: 3rem;
      }
    }
  }
  ol{
    counter-reset: ol-counter;
    list-style: none;
    &>li{
      &::before{
        content: counter(ol-counter);
        counter-increment:ol-counter;
        display: inline-block;
        color: var(--light);
        margin-left: -2.5rem;
        width: 3rem;
        font-weight: bold;
      }
    }
  }
}

//scss depending on layout it's in -----------------------------------------------------
.blog-article-layout {
  .rich-text {
    @media (min-width: $breakpoint-tablet-min) {
      width: 75%;
      margin-left: auto;
      margin-right: auto;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}
