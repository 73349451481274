//container padding
@mixin containerPadding{
  @media (min-width: $breakpoint-desktop-min){
    padding-left: $lg-pixels;
    padding-right: $lg-pixels;
  }
  @media (max-width: $breakpoint-tablet-max) and (min-width: $breakpoint-tablet-min){
    padding-left: $md-pixels;
    padding-right: $md-pixels;
  }
  @media (max-width: $breakpoint-mobile-max){
    padding-left: $sm-pixels;
    padding-right: $sm-pixels;
  }
  @media (max-width: $breakpoint-xsmobile-max){
    padding-left: $xs-pixels;
    padding-right: $xs-pixels;
  }
}
//width contained
@mixin widthContained {
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

//large width contained
@mixin widthContainedLg {
  max-width: $container-lg-width;
}
//medium width contained
@mixin widthContainedMd {
  max-width: $container-md-width;
}
//small width contained
@mixin widthContainedSm {
  max-width: $container-sm-width;
}

//base layout
.base-page .base-layout{
  &.width-contained{
    position: relative;
    @include widthContained;
    @include containerPadding;
  }
  &.width-contained-lg{
    @include widthContainedLg;
  }
  &.width-contained-md{
    @include widthContainedMd;
  }
  &.width-contained-sm{
    @include widthContainedSm;
  }
}

//base component
.base-page .base-layout:not(.width-contained) .base-component{
  &.width-contained{
    @include widthContained;
    @include containerPadding;
  }
  &.width-contained-lg{
    @include widthContainedLg;
  }
  &.width-contained-md{
    @include widthContainedMd;
  }
  &.width-contained-sm{
    @include widthContainedSm;
  }
}

@for $i from 0 through  9{
  .mt-#{$i} {
    margin-top: #{$i}em;
  }  
  .mb-#{$i} {
    margin-bottom: #{$i}em;
  } 

  .ml-#{$i} {
    margin-left: #{$i}em;
  }  
  .mr-#{$i} {
    margin-right: #{$i}em;
  } 

  .my-#{$i} {
    margin-top: #{$i}em;
    margin-bottom: #{$i}em;
  }  

  .pt-#{$i} {
    padding-top: #{$i}em;
  }  
  .pb-#{$i} {
    padding-bottom: #{$i}em;
  } 
  
  .pl-#{$i} {
    padding-left: #{$i}em;
  }  
  .pr-#{$i} {
    padding-right: #{$i}em;
  } 

  .py-#{$i} {
    padding-top: #{$i}em;
    padding-bottom: #{$i}em;
  }   
}

