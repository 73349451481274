.feature-cards-listing {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-lg-width;

    display: flex;
    flex-direction: column;
    align-items: center;



    .feature-card {
        transition: all 1s;
        margin: .75rem;
        display: block;
        position: relative;
        box-sizing: border-box;
        flex: 0 0 calc(25% - 1.5rem);
        padding: 0 5rem;
        transition: all 0.5;
        color: var(--text);
        text-align: center;
        & * {
            margin-bottom: 15px;
        }

        h3 {
            font-size: 2.4rem;
            line-height: 2.9rem;
        }
        img{
            width:50px;
        }

        &-list {
            margin-top: 3rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 6rem;
        }


        @media (max-width: $breakpoint-tablet-max){
            flex: 0 0 calc(50% - 1.5rem);

        }

        @media (max-width: $breakpoint-mobile-max){
            flex: 0 0 100%;
            margin:0 0 3rem 0;
            &:last-child{
                margin-bottom: 0;
            }
            &-list{
                flex-direction: column;
            }
        }
    }

    a.card{
        padding: 5rem 5rem 10rem 5rem;

        &::after {
            @include icon;
            @include iArrow;
            font-size:2.5rem;
            position: absolute;
            right:5rem;
            bottom:5rem;
            transition: all 0.5s;
        }

        &:hover { 
            &::after{
                color: var(--light);
                transform: translateX(.5rem);
            }
        }

        @media (max-width: $breakpoint-tablet-max){
            padding:3rem 3rem 8rem 3rem;
        }
    }
}
