.btn{
  font-family: $heading-font;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: $font-book;
  padding: 1.5rem 2.8rem;
  text-decoration: none;
  transition: 0.2s ease;
  display: inline-block;
  text-align: center;
  border: none;
  box-sizing: content-box;
  position: relative;
  top: 0px;
  border-radius: 4px;
  text-shadow: none;
  box-sizing: border-box;
  @media(max-width: $breakpoint-mobile-max){
    font-size: 1.8rem;
    line-height: 1.8rem;
    padding: 22px 20px;
  }
  @media(max-width: $breakpoint-smmobile-max){
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: 16px 20px;
  }

  &:hover{
    text-decoration: none;
    cursor: pointer;

    .btn-arrow{
      left: 5px;
    }
  }

  &-arrow{
    position: relative;
    left: 0px;
    margin-left: 20px;
    font-size: 26px;
    transition: 0.2s ease;
    &::after{
      @include icon;
      @include iArrow;
    }
  }

  //primary --------------------
  &-primary{
    color: var(--light-back-link);
    background-color: var(--light);

    .btn-container{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn-arrow{
      flex: 0 0 20px;
    }
    .btn-text{
      text-align: left;
    }
    &:hover{
      color: var(--light-back-link);
    }
  
  }

  //secondary --------------------
  &-secondary{
    color: var(--text);
    border: 1px solid var(--text);

    .btn-container{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn-arrow{
      flex: 0 0 20px;
    }
    .btn-text{
      text-align: left;
    }
    &:hover{
      color: var(--text);
    }
  }

  //tertiary, tertiary-sm --------------------
  &-tertiary, &-tertiary-sm{
    color: var(--text);
    padding: 0px;
    .btn-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn-arrow{
      position: relative;
      left: 0px;
      transition: left 0.2s ease;
    }

    @media (hover: hover) {
      &:hover{
        .btn-arrow{
          left: 3px;
        }
      }
    }

    &.light{
      .btn-text,
      .btn-arrow{
        color: var(--light);
      }
    }
 
  }

  &-tertiary{
    @media(max-width: $breakpoint-mobile-max){
      font-size: 1.6rem;
    }   

    .btn-arrow{
      margin-left: 10px;

      @media(max-width: $breakpoint-mobile-max){
        font-size: 20px;
      }
    }
  }

  &-tertiary-sm{
    font-size: 1.6rem;

    .btn-arrow{
      top: 2px;
      margin-left: 6px;
      font-size: 20px;
    }
  }

}

.btn-group{
  margin-top:3rem;
  display: flex;
  flex-direction: row;
  a{
    margin-right:1rem;
    &:last-child{
      margin-right:unset;
    }
  }

  &__center{
    justify-content: center;
  }

  @media (max-width: $breakpoint-smtablet-max){
    width:100%;
    flex-direction: column;
    a{
      margin-bottom:1.5rem;
      margin-right:unset;
    }
  }
}