//base page
html {
  background-color: $grey;
}
.base-page {
  background-color: $white;
  max-width: 2450px;
  margin: auto;
  box-shadow: 0 0px 40px rgba(0, 40, 104, 0.07);
  padding: 0px;
}

.base-section {
  position: relative;
}

.border-seperator {
  margin-top: 6rem;
  padding-top: 6rem;
  border-top: 2px solid $grey;
}

//basic column -------------------------------------------------
.cols {
  .base-container {
    display: grid;
    width: 100%;
    box-sizing: border-box;
  }
  &-2 .base-container {
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
}

//layout styles -------------------------------------------------
.light-back {
  //padding: 1px 0px;
  background-color: var(--light);
  color: var(--light-back-text);

  a:not(.btn):not(.card){
    color: var(--light-back-link);
    &:hover{
      filter: brightness(90%);
    }
  }

  hr{
    background-color: var(--tertiary);
  }

  .btn {
    //secondary --------------------
    &-secondary {
      color: var(--light-back-text);
      border: 1px solid var(--light-back-text);

      &:hover {
        color: var(--light-back-text);
      }
    }

    //tertiary, tertiary-sm --------------------
    &-tertiary,
    &-tertiary-sm {
      color: var(--light-back-text);
    }
  }
}

.grey-back {
  //padding: 1px 0px;
  background-color: var(--grey);
  color: var(--grey-back-text);

  a:not(.btn):not(.card){
    color: var(--grey-back-link);
    &:hover{
      filter: brightness(90%);
    }
  }
}

.tertiary-back {
  //padding: 1px 0px;
  background-color: var(--tertiary);
  color: var(--tertiary-back-text);

  a:not(.btn, .card){
    color: var(--tertiary-back-link);
    &:hover{
      filter: brightness(90%);
    }
  }
}

.dark-back {
  //padding: 1px 0px;
  background-color: var(--dark);
  color: var(--dark-back-text);

  a:not(.btn):not(.card){
    color: var(--dark-back-link);
    &:hover{
      filter: brightness(90%);
    }
  }

  .btn {
    //secondary --------------------
    &-secondary {
      color: var(--dark-back-text);
      border: 1px solid var(--dark-back-text);

      &:hover {
        color: var(--dark-back-text);
      }
    }

    //tertiary, tertiary-sm --------------------
    &-tertiary,
    &-tertiary-sm {
      color: var(--dark-back-text);
    }
  }
}

.dark-back-lighter {
  //padding: 1px 0px;
  background-color: var(--dark);
  color: $white;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--dark);
    filter: brightness(122%);
  }
}
