$body-font: var(--body);
$heading-font: var(--heading);

$font-regular: 400;
$font-bold: 600;
$font-book: 500;

html, div{
  font-family: $body-font;
  font-size: 10px;
}

body{
  margin: 0px;
  color: var(--text);
}

h1, h2, h3, h4, h5, h6, .h2, .h3{
  margin-top: 0px;
  margin-bottom: 0.5rem;
  font-family: $heading-font;
}

h1,h2{

 font-weight: $font-bold;
}
 h3, h4, .h2, .h3, .h4, h5, h6{
  font-weight: $font-regular;
}

p, ul, ol, input, textarea, label, select, option{
  font-family: $body-font;
  font-size: 1.6rem;
  line-height: 2.2rem;
}

p{
  margin-top: 0rem;
  margin-bottom: 1.5rem;
}

a{
  color: var(--link);
  text-decoration: none;
  transition: 0.35s ease;
  &:hover{
    color: var(--dark);
    cursor: pointer;
    text-decoration: none;
  }

  &.no-hover{
    text-decoration: none;
    color: var(--text);
    &:hover{
      color: var(--text);
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.rt-standard{
  ul{
    list-style: none !important;
    padding-left: 0;
    li{
      position: relative;
      padding-left: 17px;
      margin-bottom: 15px;
      &::before{
        content: '';
        background-color: var(--dark);
        position: absolute;
        width: 7px;
        height: 7px;
        left: 0px;
        top: 7px;   
        opacity: 0.35;
      }
    }
  }
}

ul{
  list-style: none;
  padding-left: 0;
  
  &.no-dots{
    li{
      padding-left: 0px;
      margin-bottom: 0px;
      &::before{
        content: none;
        position: absolute;   
      }
    }
  }  
}

.body-lg p{
  font-size: 2.1rem;
  line-height: 3.8rem;
}

.font-bold{
  font-weight: $font-bold;
}

.hero-title{
  font-size: 8rem;
  line-height: 8rem;
}

h1, .h1{
  font-size: 7.2rem;
  line-height: 8.6rem;
  margin-bottom:3rem;
}

h2, .h2{
  font-size: 5rem;
  line-height: 6.2rem;
  margin-bottom:3rem;
}

h3, .h3{
  font-size: 4rem;
  line-height: 5rem;
  margin-bottom:3rem;
}

h4, .h4{
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom:3rem;
}

h5{
  font-size: 2rem;
  line-height: 2.8rem;
  margin-bottom:1.5rem;
}

h6, small, small a{
  font-size: 1.4rem;
  line-height: 1.4rem;
}

.large-p{
  p{
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

hr{
  background-color: var(--light);
  height: 6px;
  max-width: 240px;
  margin-left:0;
  border-style: none;

  [class*="__center"] &{
    margin-left:auto;
  }
}

.font-bolded{
  font-weight: $font-bold;
}
.font-light{
  color: var(--light);
  font-weight: $font-bold;
}
.font-tertiary{
  color: var(--tertiary);
}
.font-book{
  font-weight: $font-book;
}
.font-50{
  opacity: 0.5;
  color: var(--text);
}

@media (max-width: $breakpoint-mobile-max){
  .hero-title{
    font-size: 4rem;
    line-height: 4rem;
  }

  h1{
    font-size: 4rem;
    line-height: 4rem;
  }

  h2, .h2{
    font-size: 3rem;
    line-height: 3rem;
  }

  h3, .h3{
    font-size: 2.4rem;
    line-height: 2.4rem;
  }

  h4, .h4{
    font-size: 2rem;
    line-height: 2rem;
  }

  h5{
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}

