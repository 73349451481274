.form {
    max-width: $container-sm-width;
    margin: 0 auto;

    &-row{
        display: flex;
        flex-wrap: wrap;
    }

    &-col{
        
        width:100%;
        box-sizing:border-box;
        justify-content: space-between;


        @media(min-width: $breakpoint-tablet-min){
            flex: 1 0 0;
            width: calc(50% - .75rem);
            padding:0 .75rem;
    
            &:first-child{
                padding-left:0;
            }
    
            &:last-child{
                padding-right:0;
            }
        }
    }

    fieldset{
        border:none;
        background:none;
        padding:0;
    }


    &-field {
        margin-bottom: 1.5rem;

        label {
            display: block;
            margin-bottom: 0.5rem;
        }

        input[type="text"],
        textarea,
        select {
            box-sizing: border-box;
            padding: 1.5rem;
            width: 100%;
            border: 2px solid var(--dark);
            border-radius: 0.5rem;
        }



        &__error{
            
            span.field-validation-error {
            padding-top: 0.5rem;
            display: block;
            color: palevioletred;
            font-size: 1.3rem;
            font-weight: bold;
            }

            input[type="text"],
            textarea,
            select {
                border-color: red;
                box-shadow: 0 0 5px palevioletred;
            }
        }
    }
}
