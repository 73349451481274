$white:         #fff;
$black:         #000;
$grey:          #F4F4F4;
$grey-back:     #F5F5F5;
$grey-border:   #C3CED6;

$form-colour:   #838486;
$form-border:   #DDDEE0;
$disabled:      #DDDEE0;
$error-border:  #FF0000;
$error-back:    #FFEBEB;