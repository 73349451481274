.title{
    h1,h2,h3{
        margin-bottom: 3rem;
    }

    position: relative;
    overflow: hidden;
    &__center{
        text-align: center;
        hr{
          margin:3rem auto;
        }
    }
}