.video {
  button {
    position: relative;
    background: none;
    border: none;
    padding: 0;
    display: block;
    margin: 0 auto;
    max-width: 705px;

    img {
      width: 100%;
      display: block;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--dark);
      opacity: 0.6;
    }

    &:before {
      content: "\e913";
      color: white;
      font-size: 7rem;
      display: block;
      position: absolute;
      width: 7rem;
      height: 7rem;
      background: var(--light);
      right: 0;
      bottom: 0;
      z-index: 100;

      font-family: "custom-icons" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.modal-video-container{
  padding: 0px ;

 .modal-box{
   background-color: transparent;
   display: flex;
   width: 100%;
   min-height: 100vh;
 }

 .modal-box-content{
   margin: auto;
   width: 60%;
   @media (max-width: $breakpoint-mobile-max) {
     width: 75%;
   }
   @media (max-width: $breakpoint-smmobile-max) {
     width: 100%;
   }
 }
 
 .modal-video{
   height: 505px;
   width: 100%;
   max-width: 891px;
   margin: auto;
   display: block;
   @media (max-width: $breakpoint-tablet-max) {
     height: 405px;
   }
   @media (max-width: $breakpoint-smtablet-max) {
     height: 350px;
   }
   @media (max-width: $breakpoint-mobile-max) {
     height: 45vw;
   }
   @media (max-width: $breakpoint-smmobile-max) {
     height: 58vw;
   }
 }
}
