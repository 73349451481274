.accordion {
    max-width: $container-lg-width;
    margin-left: auto;
    margin-right: auto;

    &-content{
        text-align: center;
        margin-bottom:3rem;
    }
    &-item {
        
        border-radius: 1.5rem;
        background-color: var(--grey);
        margin-bottom:1rem;
        padding: 1.5rem;
        transition: all .3s;
        &-title{
            cursor: pointer;
            h4{
                margin:0;
                padding-right: 3rem;
                position: relative;
                font-weight:bold;
                &:after{
                    @include icon;
                    @include iPlus;
                    display: block;
                    position: absolute;
                    right:0;
                    top:0;

                    .active & {
                        @include iMinus;
                    }
                }
            }
        }
        &-content {
            transition: all .3s;
            height: 0;
            margin: 0;
            padding: 0;
            opacity: 0;
            .active & {
            
                opacity: 1;
                height: auto;
                margin-top:1.5rem;
            }
        }


    }
}
