.listing {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-lg-width;

    &-items {
        display: grid;
        align-items: stretch;
        grid-template-columns: 1fr 1fr 1fr;
        gap:3rem;

        &-item {
            display: flex;
            flex-direction: column;

            .multi & {
                margin-bottom: 6rem;
            }

            &-image {
                img {
                    width: 100%;
                    margin-bottom: 3rem;
                }
            }
            &-content {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                h3 {
                    margin-bottom: 1.5rem;
                }
                p {
                    margin-bottom: 3rem;
                }

                /*content container div*/
                div:nth-child(1) {
                    flex-grow: 1;
                }

                /*button container div*/
                div:nth-child(2) {
                    flex-shrink: 1;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
        @media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
            grid-template-columns: 1fr;
            &-item {
                display: flex;
                flex-direction: row;
                margin-right: unset;
                margin-bottom: 6rem;

                &-image {
                    flex-basis: 45%;
                    max-width: 45%;
                    img {
                        object-fit: contain;

                        width: 100%;
                        max-height: 100%;
                    }
                }

                &-content {
                    margin-left: 3rem;
                    flex-basis: 55%;
                    flex-grow: 1;

                    /*content container div*/
                    div:nth-child(1) {
                        flex-grow: unset;
                    }

                    /*button container div*/
                    div:nth-child(2) {
                        flex-shrink: unset;
                    }
                }

                &:last-child {
                    margin-bottom: 3rem;
                }
            }
        }

        @media (max-width: $breakpoint-mobile-max) {
            grid-template-columns: 1fr;
            &-item {
                margin-right: unset;
                margin-bottom: 6rem;

                &:last-child {
                    margin-bottom: 3rem;
                }
            }
        }
    }
}
