//image styles (global)  -------------------------------------------------
.base-image{
  overflow: hidden;
  position: relative;
  &.ratio{
    img{
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      margin-bottom: -4px;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10 and IE11 */
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        max-width: 300%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.auto-height{
    img{
      margin-bottom: -4px;
      width: 100%;
      height: auto;
    }
  }
}

.base-back-image{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.image{
  figure{
    position: relative;
    margin:0 auto;
    max-width: 1440px;

    img{
      width: 100%;
    }
    figcaption{
      margin-top: 3rem;
      color: var(--light);
      font-size:2rem;
      text-align: center;
    }
  }
}

img{
  &.cover{
    object-fit: cover;
    object-position: center;
    width:100%;
    height:100%;
  }
}