.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    display: flex;
    li {
      a {
        background-color: var(--grey);
        border-right: var(--light-border) 1px solid;
        display: block;
        padding: 0 3rem;
        font-size: 2rem;
        line-height: 6rem;
        color: var(--dark);
        i {
          display: inline-block;   
        }

        &:hover {
          background-color: var(--light);
          color: var(--light-back-text);
        }
      }

      &.prev{
        i{
          transform: rotate(180deg);
        }
      }


      &:first-child {
        a {
          border-radius: 4px 0 0 4px;
        }
      }

      &:last-child {
        a {
          border-radius: 0 4px 4px 0;
          border-right: none;
        }
      }

      &.current {
        a {
          background-color: var(--dark);
          color: var(--dark-back-text);
        }
      }
    }

  
  }

  @media (max-width:$breakpoint-mobile-max){
      ul{
        li{
          display: none;
          &.next, &.prev{
            display: inline-flex;
          }

          &.prev{
            a{
              border-radius: 4px 0 0 4px;
            }
          }
        }

      }
  }
}
