.error-404{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-content{
    padding-top: 60px;
    padding-bottom: 90px;
    max-width: 700px;
    margin: auto;
    @media (max-width: $breakpoint-mobile-max) {
      padding-top: 45px;
    }

    h1{
      font-size: 9rem;
      line-height: 10.8rem;
      @media (max-width: $breakpoint-mobile-max) {
        font-size: 8rem;
        line-height: 9.8rem;
      }
    }

    h2{
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }

  &-buttons{
    margin-top: 30px;
  }

  &-lines {
    position: relative;
    height: 90px;
    width: 100%;
    overflow: hidden;
  
    svg {
      position: absolute;
      min-width: 100%;
  
      @media (max-width: 1920px) {
        min-width: unset;
        height: 90px;
      }
    }
  }
  
}

