.logo-grid{
    max-width: $container-lg-width;
    margin: 0 auto;
    &-items{

        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &-item{
        position:relative;
        box-sizing: border-box;
        width: 144px;
        height:100px;
        padding:1rem;

        display: flex;
        align-items: center;
        justify-content: center;

        img{
            max-width: 100%;
            max-height: 100%;
        }
    }
}