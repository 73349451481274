.stats {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-sm-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &-list {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 3rem;
        margin-top: 3rem;

        @media (max-width: $breakpoint-tablet-max) {
            flex-wrap: wrap;
        }

        .card {
            box-sizing: border-box;
            flex: 0 0 25%;
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            border-right: var(--dark-border) 1px solid;

            .dark-back & {
                border-right: rgba(255, 255, 255, 0.2) 1px solid;
            }
            &:last-child {
                border-right: none;
            }

            &-inner {
                margin-top: -1.5rem;
                margin-bottom: -1.5rem;
                padding: 0 3rem;
                h3 {
                    font-size: 6rem;
                    font-weight: bold;
                    padding: 0;
                    margin: 0 0 1.5rem;
                }
                h4 {
                    font-size: 2rem;
                    margin: 0;
                    padding: 0;
                }
            }

            @media (max-width: $breakpoint-tablet-max) {
                flex: 0 0 50%;
                border-bottom: var(--dark-border) 1px solid;

                margin-top: 0;
                margin-bottom: 0;

                &:nth-child(even), &:last-child {
                    border-right: none;
                }

                &-inner {
                    margin-top: 3rem;
                    margin-bottom: 3rem;
                    padding: 0;
                    h3 {
                        font-size: 4rem;
                    }
                }

                .dark-back & {
                    border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
                }

                &:nth-child(n + 3){
                    margin-bottom: 0;
                    border-bottom: none;
                }
            }
        }
    }
}
