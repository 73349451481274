.contact-card {
    box-sizing: border-box;
    background:$white; 
    &-full {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }
    padding: 6rem;
    display: flex;

    &-map {
        padding-top: 0 !important;

        .contact-card-details {
            min-width: 100%;
        }

        img {
            width: 100%;
            margin-bottom: 3rem;
        }

        .adr {
            display: block;
            margin-bottom: 2rem;
        }

        .phone a {
            font-size: 18px;
            font-weight: 500;
        }
    }

    box-shadow: 0px 0px 50px rgba(34, 32, 89, 0.1);

    &-details {
        display: flex;
        flex: 0 0 41%;
        margin-right: 3rem;

        h3 {
            font-size: 32px;
            line-height: 43px;
            font-weight: 500;
            margin: 0;
        }
        p {
            font-size: 16px;
            line-height: 29px;
            margin: 0;
            &.category {
                opacity: 0.5;
                font-size: 18px !important;
                font-weight: 500;
            }

            &.phone a {
                color: var(--text);
            }
        }

        .social {
            margin-top: 1rem;

            i {
                font-size: 2rem;
                color: var(--text);
                opacity: 0.5;
                margin-right: 1rem;
            }
        }

        &-image {
            border-radius: 50%;
            width: 173px;
            height: 173px;
            margin-right: 2rem;
        }
    }

    &-bio {
        .btn {
            margin-top: 1.5rem;
        }
    }

    @media (max-width: $breakpoint-tablet-max) {
        padding: 3rem;

        &-details {
            margin: 0 3rem 0 0;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img {
                margin-right: unset;
                margin-bottom: 1.5rem;
            }
        }
    }

    @media (max-width: $breakpoint-mobile-max) {
        flex-direction: column;
        &-details {
            margin: 0;
        }

        &-full {
            .contact-card-details {
                margin-bottom: 1.5rem;
            }
        }
    }
}

.contact-list {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-lg-width;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    &.ipr-1{
        max-width: $container-sm-width;
        grid-template-columns: 1fr;
    }
    &.ipr-2{
        max-width: $container-sm-width;
        grid-template-columns: 1fr 1fr;
    }

    &.ipr-3{
        max-width: $container-md-width;
        grid-template-columns: 1fr 1fr 1fr;
    }


    row-gap: 1.5rem;
    column-gap: 1.5rem;

    .contact-card {
        
        padding: 6rem 0;
        justify-content: center;

        &-video{
            
            grid-column: 1/3;
            grid-row: 1;
            padding:0;

            .video{
                width:100%;
            }

            button{
                max-width: unset;
                height: 100%;
                width:100%;
                img{
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &-details {
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-right: unset;

            h3 {
                font-size: 24px;
                line-height: 29px;
                font-weight: 500;
                margin: 0;
            }
            p {
                font-size: 14px;
                line-height: 20px;
                margin: 0;
                &.category {
                    opacity: 0.5;
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 500;
                    margin-bottom: 1.5rem;
                }
            }

            &-image {
                margin: 0 0 1.5rem 0;
                border-radius: 50%;
                width: 120px;
                height: 120px;
            }
        }
    }

    @media (max-width: $breakpoint-smtablet-max) {
        grid-template-columns: 1fr 1fr;
    
        &.ipr-3{
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (max-width: $breakpoint-mobile-max) {
        grid-template-columns: 1fr;


        &.ipr-2,
        &.ipr-3{
            grid-template-columns: 1fr;
        }

        .contact-card{
            &-video{
                grid-column: 1;
                grid-row: 1;
            }
        }
    }
}
