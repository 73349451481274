.blog-post{
    
    .article-image{
        width: 100%;
        height: calc(70vh - 10rem);
        object-fit: cover;
        object-position: center ;
    }
    .article-inner{
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        @include widthContainedMd;
        margin-top:-30vh;
        background-color: $white;
        z-index: 100;
        position: relative;
        padding:6rem 9rem;
        border-bottom:1px solid var(--grey);

        @media (max-width: $breakpoint-tablet-max) {
            padding:6rem;
            margin-top:0;
        }

        @media (max-width: $breakpoint-mobile-max) {
            padding:3rem;
        }
    }
}