.awards {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-lg-width;

    &-item {
        &-wrapper {
            padding: .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .VueCarousel{
        align-items: center;
    }
    .VueCarousel-wrapper{
        max-width: 800px!important;
    }

    .VueCarousel-navigation {
        width:100%;
        margin-top:3rem;
        display: flex;
        justify-content: flex-end;

        @media (max-width: $breakpoint-mobile-max) {
            justify-content: center;
        }

    }
    .VueCarousel-navigation-button {
        display: block;
        position: relative !important;
        color: transparent !important;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 0;
        margin: 0 !important;
        padding: 0 !important;
        &::after {
            color: var(--light);
            @include icon;
            @include iArrow;
            font-size: 2.5rem;
            line-height: 1;
            position: absolute;
            right: 5rem;
            bottom: 5rem;
            top: 0;
            right: 0;
        }
        &:focus{
            outline: unset!important;
        }

        &.VueCarousel-navigation-next {
            margin-left: .5rem !important;
            transform: unset;
        }
        &.VueCarousel-navigation-prev {
            transform: unset;
            &::after {
                bottom: 1px;
                left: 0;
                transform: rotate(180deg);
            }
        }
    }
}
