.social-sharing{

  &-title{
    font-size: 1.8rem;
    font-weight: $font-bold;
    margin-bottom: 0px;
  }

  &-row{
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  }

  &-link{
    margin: 15px 8px;

    .las, .lab{
      font-size: 24px;
      line-height: 24px;
      display: block;
    }
  }

}

//scss depending on the layout it's in --------------------
.blog-article-layout{
  .social-sharing{
    margin-top: 120px;
    text-align: center;
    &-row{
      justify-content: center;
    }  
  }  
}

.sidebar-layout{
  .social-sharing{
    margin-top: 60px; 
  }  
}