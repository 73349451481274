//all popups -----------------------------------------------------
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-top: 8vh;
  -webkit-overflow-scrolling: touch;
  background: rgba(34,33,89,.9);
  opacity: 0;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;

  @media (max-width : 540px) {
    top: 0px;
    display: block;
    padding-top: 60px;
    width: 100%;
  }  

  @supports (backdrop-filter: blur(12px)) {
    .modal::before {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      backdrop-filter: blur(18px);
      pointer-events: none;
    }
  }

  &.visible {
    visibility: visible;
    opacity: 1;

    .modal-box{
      animation: scale .2s cubic-bezier(.68, -.55, .265, 1.55) forwards;
    }
  }

  &-close{
    position: fixed;
    top: 120px;
    right: 120px;
    z-index: 1000;
    padding: 0;
    border: none;
    background-color: transparent;
    color: $white;
    cursor: pointer;
    @media (max-width: $breakpoint-lgdesktop-max) {
      top: 90px;
      right: 90px;
    }
    @media (max-width: $breakpoint-tablet-max) {
      top: 60px;
      right: 60px;
    }
    @media (max-width: $breakpoint-mobile-max) {
      top: 20px;
      right: 20px;
    }

    &::before{
      @include icon;
      @include iCross;
      font-size: 32px;
    }

    &:hover {
      color: $white;
    }
  }

  &-box {
    position: relative;
    flex-shrink: 0;
    width: 60%;
    background: $white;
    opacity: 1;
    cursor: auto;
    will-change: transform, opacity;

    @media (max-width : $breakpoint-smmobile-max) {
      width: auto;
      border-radius: 0;    
    }

    &-content {
      padding: 30px;
      @media (max-width: $breakpoint-mobile-max) {
        padding: 60px 0px;
      }
  
    }
  }

}

// animations ---------
@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}