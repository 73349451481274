.page-share {
    &-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        h5 {
            font-weight: 600;
        }

        ul {
            display: flex;
            li {
                padding: 0.5rem;
                i {
                    font-size: 3rem;
                }
                button{
                    border:none;
                    background-color: transparent;
                    padding:0;
                    margin:0;
                    color: var(--light);
                    transition: all .3s;
                    &:hover{
                        color: var(--dark);
                    }
                }
            }
        }

        @media (min-width: $breakpoint-desktop-min) {
            h5 {
                font-size: 1.5rem;
            }
            ul {
                flex-direction: column;
            }
        }
    }

    @media (min-width: $breakpoint-desktop-min) {
        position: absolute;
        right: -4rem;
        top:0;   
        height:100%;
        box-sizing: border-box;
        margin-top:30vh;
        &-inner{
            top:0;
            position:sticky;
            padding-top:5rem;
            margin-bottom: 30vh;
        }
    }
}
