//padding/margins
$xs-pixels: 1.5rem;
$sm-pixels: 3rem;
$md-pixels: 6rem;
$lg-pixels: 9rem;
$xl-pixels: 12rem;

//gutter spacing
$gutter: 3rem;

// box-shadow
@mixin boxShadow {
  box-shadow: 0px 0px 50px rgba(34, 32, 89, 0.1);
}
@mixin boxShadowMobile {
  box-shadow: 0px 0px 40px rgba(34, 32, 89, 0.1);
}
@mixin noBoxShadow {
  box-shadow: 0px 0px 0px rgba(34, 32, 89, 0.1);
}

//container widths
$container-lg-width: 1440px;
$container-md-width: 1180px;
$container-sm-width: 980px;

//overlay opacity
$overlay-opacity: 0.75;

//desktop/tablet/mobile
$breakpoint-desktop-min: 1275px;

$breakpoint-lgdesktop-min: 1400px;
$breakpoint-lgdesktop-max: 1600px;

$breakpoint-tablet-min: 769px;
$breakpoint-tablet-max: 1274px;
$breakpoint-lgtablet-min: 999px;
$breakpoint-smtablet-max: 998px;

$breakpoint-mobile-max: 768px;
$breakpoint-mobile-min: 551px;
$breakpoint-smmobile-max: 550px;
$breakpoint-xsmobile-max: 400px;