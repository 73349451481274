.features {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-md-width;


    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 3rem;
    }

    th {
        height:10rem;
        font-size: 2rem;
        font-weight: 500;
        &.headline {
            text-align: left;
        }

        &:nth-child(2) {
            background-color: var(--light);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            color: var(--light-back-text);
            padding-left:1.5rem;
            padding-right:1.5rem;
        }
    }
    tbody {
        td {
            border-top: 1px solid var(--light-border);
            font-size: 1.6rem;
            padding: 1.5rem 0;

            &.selected {
                background-color: var(--dark);
            }
            &:nth-child(n + 2) {
                text-align: center;
            }

            &:nth-child(2) {
                position: relative;
                box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.35);
                border-top: 1px solid var(--text);
                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-color: var(--dark);
                    top: 0;
                    left: 0;
                    z-index: 100;
                }
            }
        }
        tr {
            &:last-child {
                td:nth-child(2) {
                    &:before {
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }
        }
    }

    svg {
        position: relative;
        z-index: 150;
        &.tick {
            fill: var(--light);
        }

        &.cross {
            fill: var(--dark);
            fill-opacity: 0.8;
        }
    }
}

.dark-back{
    .features{

        tbody {
            td {
                border-top: 1px solid var(--dark-border);

            }
        }
        svg{
            &.cross{
                fill: var(--light-back-text);
                fill-opacity: .8;
            }
        }
    }
}
