.testimonials {
    &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include boxShadow;
        margin: 3rem 1.5rem;
        padding: 3rem;

        &-rating {
            margin-bottom: 1.5rem;
            .rating-star {
                font-size: 2rem;
                color: #FFCB05;
            }
        }

        p{
            text-align: center;
        }
    }

    .VueCarousel {
        align-items: center;
    }

    .VueCarousel-wrapper {
        padding: 1rem 0;
    }

    .VueCarousel-navigation {
        position: absolute;
        top: 0;
        box-sizing: border-box;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;

        @media (min-width: $breakpoint-desktop-min) and (max-width: 1440px) {
            padding-left: 9rem;
            padding-right: 9rem;
        }
        @media (max-width: $breakpoint-tablet-max) and (min-width: $breakpoint-tablet-min) {
            padding-left: $md-pixels;
            padding-right: $md-pixels;
        }
        @media (max-width: $breakpoint-mobile-max) {
            padding-left: $sm-pixels;
            padding-right: $sm-pixels;
            position: relative;
            top:unset;
            justify-content: center;
        }
        @media (max-width: $breakpoint-xsmobile-max) {
            padding-left: $xs-pixels;
            padding-right: $xs-pixels;
        }
    }
    .VueCarousel-navigation-button {
        display: block;
        position: relative !important;
        color: transparent !important;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 0;
        margin: 0 !important;
        padding: 0 !important;
        &::after {
            color: var(--light);
            @include icon;
            @include iArrow;
            font-size: 2.5rem;
            line-height: 1;
            position: absolute;
            right: 5rem;
            bottom: 5rem;
            top: 0;
            right: 0;
        }
        &:focus {
            outline: unset !important;
        }

        &.VueCarousel-navigation-next {
            margin-left: 0.5rem !important;
            transform: unset;
        }
        &.VueCarousel-navigation-prev {
            transform: unset;
            &::after {
                bottom: 1px;
                left: 0;
                transform: rotate(180deg);
            }
        }
    }
}
