.footer {
    padding-top: 6em;
    padding-bottom: 6em;
    position: relative;
    @include widthContained;
    @include containerPadding;

    h4 {
        font-weight: 500;
    }
    &-inner {
        max-width: $container-lg-width;
        margin: 0 auto;
        &-top {
            display: flex;
            margin-bottom: 3rem;
            @media (max-width: $breakpoint-smtablet-max) {
                display: none;
            }
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: $breakpoint-smtablet-max) {
                flex-direction: column-reverse;
            }
        }
    }

    &-address {
        flex: 0 0 25%;
        a {
            &:hover {
                color: #fff;
            }
        }
    }

    &-quick-links {
        flex: 0 0 75%;
        .quick-links {
            display: flex;
            ul {
                margin: 0;
                padding: 0;
                flex: 0 0 33%;
                li {
                    line-height: 2em;
                    a {
                        color: var(--dark-back-text);
                    }
                }
            }
        }
    }

    &-legals {
        flex: 0 0 40%;

        .affiliations {
            margin-bottom: 3rem;
            display: flex;
            justify-content: flex-start;

            img {
                margin-right: 3rem;
            }
        }
        p {
            opacity: 0.5;
        }

        a {
            &:hover {
                color: #fff;
            }
        }

        @media (max-width: $breakpoint-smtablet-max) {
            text-align: center;
            flex: 0 0 100%;
            .affiliations {
                justify-content: center;
                img {
                    margin: 0 1rem;
                    max-width: 70px;
                }
            }
        }
    }

    &-socials {
        h4 {
            display: inline-block;
            padding-right: 1.5rem;
            margin-right: 1.5rem;
            line-height: 4rem;
            border-right: 1px solid $white;
            &:last-child {
                margin-right: 0;
                padding-right: 0;
                border-right: none;
            }
        }
        i {
            font-size: 3rem;
            margin-right: 1rem;
        }

        a {
            &:hover {
                color: #fff;
            }
        }

        @media (max-width: $breakpoint-smtablet-max) {
            margin-bottom: 3rem;
            text-align: center;

            h4 {
                margin: 0;
                border-right: none;
                display: block;
            }
        }
    }
}
