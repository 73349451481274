.ebook {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-lg-width;

    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "content image";

    &-content {
        padding-right: 5%;
        grid-area: content;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
            margin-bottom: 3rem;
        }
        > div {
            padding: $xs-pixels;
            padding-right: 0;
            max-width: calc(#{$container-lg-width}/ 2 - 6rem);
        }
    }

    &-image {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5%;
        grid-area: image;

        img {
            margin-top:-15rem;
            margin-bottom:-15rem;
            margin-right:-15rem;
            width: 100%;
        }
    }

    @media (max-width: $breakpoint-mobile-max) {
        grid-template-columns: 1fr;
        grid-template-areas: "image" "content";

        &-content {
            padding-right: 0;
            padding-left: 0;
            > div {
                max-width: unset;

                @media (max-width: $breakpoint-tablet-max) and (min-width: $breakpoint-tablet-min) {
                    padding-left: $md-pixels;
                    padding-right: $md-pixels;
                }
                @media (max-width: $breakpoint-mobile-max) {
                    padding-left: $sm-pixels;
                    padding-right: $sm-pixels;
                }
                @media (max-width: $breakpoint-xsmobile-max) {
                    padding-left: $xs-pixels;
                    padding-right: $xs-pixels;
                }
            }
        }
        &-image {
            margin-right: 0;
            margin-left: 0;
            img{
            margin-bottom:0;
            margin-right: 0;
            margin-left: 0;
            }
        }
    }
}
 